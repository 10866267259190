import { useLocation, useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import {
  ErrorType,
  ListItemType,
  OptionsStateType,
  QueryParamItem,
  QuizOptionType,
  QuizStateType,
  SelectValuesItem,
  StringToFileImgType
} from 'cellar/src/ts-types'
import {
  ProjectTypeEnum,
  IProject,
  IQuest,
  IStep,
  IOnChainStep,
  IOffChainStep,
  IQuizInput
} from '@repo/types'
import { ICreationForm, DateStateType } from 'cellar/src/pages/Quests/ts-types'
import {
  ICreationForm as IStepMainStateCreationForm,
  IOffChainFormState,
  IOnChainAddressesState,
  IOnChainFormState
} from 'cellar/src/pages/Steps/ts-types'
import { quizOptionInit } from '../constants'

export type ProjectSocialLinks = {
  socialLinks: {
    value: string[]
    error: boolean | string
    touched: boolean
  }
}

export type TestFieldType = {
  value: string
  error: boolean | string
  touched: boolean
  validations: FieldValidations // Holds parameters for validations
}

export interface FormStateType {
  [key: string]: TestFieldType
}

export type ProjectFormState = FormStateType & ProjectSocialLinks

export const useCustomRouter = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const routeDefaultParams: Record<string, Record<string, string>> = {
    '/users': { userPage: '1', perPage: '10', userQuery: '' },
    '/projects': { page: '1', perPage: '10', query: '', typeId: '' },
    '/project-types': { typePage: '1', perPage: '10', typeQuery: '' },
    '/quests': { page: '1', perPage: '10', query: '' },
    '/steps': {
      stepsPage: '1',
      perPage: '10',
      stepsQuery: '',
      questId: '',
      type: ''
    }
  }

  const navigateWithParams = (url: string | URL, options?: any) => {
    const targetUrl = typeof url === 'string' ? url : url.toString()

    const urlObj = new URL(targetUrl, window.location.origin)
    const searchParams = new URLSearchParams(urlObj.search)

    const defaultParams = routeDefaultParams[urlObj.pathname]
    if (defaultParams) {
      Object.entries(defaultParams).forEach(([key, value]) => {
        if (!searchParams.has(key)) {
          searchParams.set(key, value)
        }
      })
    }
    navigate(`${urlObj.pathname}?${searchParams.toString()}`, options)
  }

  return {
    pathname: location.pathname,
    searchParams: new URLSearchParams(location.search),
    navigate: navigateWithParams
  }
}

// Update userFormFields to handle optional password correctly
export function userFormFields(
  fields:
    | any
    // IAdmin
    | undefined
): FormStateType {
  let form: FormStateType = {
    email: {
      value: fields?.email ?? '',
      error: false,
      touched: false,
      validations: {
        isEmail: true
      }
    },
    nickname: {
      value: fields?.nickname ?? '',
      error: false,
      touched: false,
      validations: {
        required: true
      }
    }
  }

  if (!fields) {
    form.password = {
      value: '',
      error: false,
      touched: false,
      validations: {
        minLength: 6
      }
    }
  }

  return form
}

// export function userEditFields(user: IAdmin): IUserForm | undefined {
//   return typeof user === 'object'
//     ? {
//         email: { value: user.email, error: false, touched: false },
//         nickname: { value: user.nickname, error: false, touched: false },
//         password: { value: user.password, error: false, touched: false }
//       }
//     : undefined
// }

export function contractAddressInitialState(data: string[] | null) {
  return data && data.length
    ? data.map((el) => {
        return {
          value: el,
          error: false
        }
      })
    : [{ value: '', error: false }]
}

export function projectInitialFields(data: IProject | boolean): any {
  const result: IProject | null = data === true ? null : (data as IProject)
  return {
    name: {
      value: result ? result.name : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    logo: {
      value: result ? result.logo : '',
      error: false,
      touched: false,
      validations: { fileSize: 10000000, required: true }
    },
    description: {
      value: result ? result.description : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    typeId: {
      value: result ? result.typeId : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    website: {
      value: result ? result.website : '',
      error: false,
      touched: false,
      validations: { isWebSite: true }
    },
    socialLinks: {
      value: result
        ? result.social_links.map((el) => {
            return { value: el, error: '' }
          })
        : [{ value: '', error: false }],
      error: false,
      touched: false
    }
  }
}

export const getContractAddress = (data: ListItemType[]): null | string[] => {
  const result: string[] = []

  data.forEach((el) => {
    if (el.value) {
      result.push(el.value)
    }
  })

  return result.length ? result : null
}

// export const getImagesList = async (
//   images: string[]
// ): Promise<StringToFileImgType> => {
//   const errors: string[] = []
//   const result: File[] = []
//   if (images && images) {
//     images.forEach((url) => {
//       ;(async () => {
//         try {
//           const response = await fetch(url)
//           const blob = await response.blob()
//           const file = new File([blob], url, { type: blob.type })
//           result.push(file)
//         } catch (error) {
//           errors.push('cant find the' + url)
//         }
//       })()
//     })
//   }
//
//   return { result: result, errors: errors }
// }

export const getImagesList = async (
  images: string[]
): Promise<StringToFileImgType> => {
  const errors: string[] = []
  const res: File[] = []

  if (images && images) {
    for (const url of images) {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        if (blob.type.startsWith('image/')) {
          const file = new File([blob], url, { type: blob.type })
          res.push(file)
        } else {
          errors.push(`Unsupported image format at ${url}`)
        }
      } catch (error) {
        errors.push(`Can't find the image at ${url}`)
      }
    }
  }

  return { errors: errors, result: res }
}

export function questInitialFields(data: boolean | IQuest): ICreationForm {
  const result: IQuest | null = data === true ? null : (data as IQuest)

  return {
    project: {
      //@ts-ignore
      value: result ? (result.project as string) : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    title: {
      value: result ? result.title : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    description: {
      value: result ? result.description : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    startDate: {
      value: result ? dayjs(result.startDate) : null,
      error: false,
      touched: false
    },
    endDate: {
      value: result ? dayjs(result.endDate) : null,
      error: false,
      touched: false
    },
    images: {
      value: result ? result.images : [],
      error: '',
      touched: false
    }
  }
}

export function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime()) as T
  }

  if (obj instanceof RegExp) {
    return new RegExp(obj) as T
  }

  if (Array.isArray(obj)) {
    const arrCopy: any[] = []
    for (let i = 0; i < obj.length; i++) {
      arrCopy[i] = deepClone(obj[i])
    }
    return arrCopy as T
  }

  const objCopy: { [key: string]: any } = {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      objCopy[key] = deepClone((obj as { [key: string]: any })[key])
    }
  }
  return objCopy as T
}

// export function isEmail(value: string): boolean {
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//   return emailRegex.test(value)
// }

export const setItem = <T>(key: string, value: T): void => {
  try {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem(key, jsonValue)
  } catch (error) {
    console.error('Error setting localStorage item:', error)
  }
}

export const getItem = <T>(key: string): T | null => {
  try {
    const jsonValue = localStorage.getItem(key)
    return jsonValue ? (JSON.parse(jsonValue) as T) : null
  } catch (error) {
    console.error('Error getting localStorage item:', error)
    return null
  }
}

export const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error('Error removing localStorage item:', error)
  }
}

export const clearStorage = (): void => {
  try {
    localStorage.clear()
  } catch (error) {
    console.error('Error clearing localStorage:', error)
  }
}

export const isObject: (value: unknown) => boolean = (value) => {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

export const getErrorMessage = (error: unknown): ErrorType => {
  let result: ErrorType = null
  if (isObject(error)) {
    if ('message' in (error as object)) {
      const message: string | string[] = (
        error as { message: string | string[] }
      ).message
      if (typeof message === 'string') {
        result = message
      }
      if (Array.isArray(message)) {
        result = message.map((item) => `- ${item}`).join('\n')
      }
    }
  }
  return result
}

////////////////////////////////////////////////////////////////////////////////////

type ValidationFunction = (value: any, arg?: any) => boolean | string

export type FieldValidations = {
  [key: string]: any
}

// Validation functions as you defined them (updated to match common practices)
const required = (value: string | File): boolean | string => {
  return typeof value === 'string'
    ? value.trim() !== ''
      ? true
      : 'This field is required.'
    : value
      ? true
      : 'This field is required.'
}

export const isEmail = (value: string): boolean | string => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(value) ? true : 'Please enter a valid email address.'
}

export const isWebSite = (value: string): boolean | string => {
  const websiteRegex =
    /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s]*)?$/
  return websiteRegex.test(value)
    ? true
    : 'Please enter a valid website address.'
}

const maxLength = (value: string, length: number): boolean | string => {
  return value.length <= length
    ? true
    : `The maximum length is ${length} characters.`
}

const minLength = (value: string, length: number): boolean | string => {
  return value.length >= length
    ? true
    : `The minimum length is ${length} characters.`
}

const fileSize = (value: { size: number }, size: number): boolean | string => {
  return value.size > size ? `File size exceeds 10MB.` : true
}

// Map of validation functions to allow dynamic access
const validationFns: { [key: string]: ValidationFunction } = {
  maxLength,
  minLength,
  isEmail,
  required,
  fileSize,
  isWebSite
}

export const validateValue = <T extends FormStateType, K extends keyof T>(
  state: T,
  key: K,
  value: string
): T => {
  const newState: T = {
    ...state,
    [key]: {
      ...state[key],
      value: value,
      touched: true
    }
  }

  const field = newState[key]
  Object.keys(field.validations).forEach(
    (validationKey: keyof FieldValidations) => {
      const arg = field.validations[validationKey] // TypeScript now knows validationKey is valid
      const validationResult = validationFns[validationKey](value, arg)

      if (validationResult !== true) {
        field.error = validationResult
      } else {
        field.error = false
      }
    }
  )

  return newState
}

export const allValidateValue = <T extends FormStateType>(
  state: T
): T | boolean => {
  let valid = false
  const newState = deepClone<T>(state)

  const keys = Object.keys(newState)

  keys.forEach((key) => {
    const item = newState[key]

    Object.keys(item.validations).forEach(
      (validationKey: keyof FieldValidations) => {
        const arg = item.validations[validationKey]
        const validationResult = validationFns[validationKey](item.value, arg)

        if (validationResult !== true) {
          item.error = validationResult
          valid = true
        } else {
          item.error = false
        }
      }
    )
  })

  return valid ? newState : false
}

export const checkDateIsValid = (value: Dayjs | null): DateStateType => {
  if (value) {
    return {
      value: value,
      error: !(value as Dayjs).isValid(),
      touched: true
    }
  } else {
    return {
      value: null,
      error: true,
      touched: true
    }
  }
}

export const getProjectsType = (): SelectValuesItem[] => {
  const keys: string[] = Object.keys(ProjectTypeEnum) as string[]

  // const keys = Object.keys(ProjectTypeEnum) as Array<
  //   keyof typeof ProjectTypeEnum
  // >
  return keys.map((key: string) => {
    return {
      title: key,
      //@ts-ignore
      value: ProjectTypeEnum[key]
      // ProjectTypeEnum[key] as string
    }
  })
}

export const generateSearchParam = (params: QueryParamItem): string => {
  let result = ''

  Object.keys(params).forEach((el) => {
    if (params[el]) {
      result =
        result + (result ? `&${el}=${params[el]}` : `${el}=${params[el]}`)
    }
  })

  return result
}

export async function getFileFromUrl(url: string) {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error('Failed to fetch image from URL')
  }

  const blob = await response.blob()

  const file = new File([blob], 'logo.jpg', {
    type: blob.type,
    //@ts-ignore
    lastModified: new Date()
  })

  return file
}

export const formatDate = (date: Date): string => {
  return date
    ? date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : ''
}

export const createHashMap = <T extends object, K extends keyof T>(
  data: T[],
  key: K
): { [key: string]: T } => {
  const result: { [key: string]: T } = {}
  data.forEach((el) => {
    result[el[key] as string] = el
  })
  return result
}

export const filterData = <T extends object, K extends keyof T>(
  data: T[],
  key: K,
  query: string
): T[] | null => {
  const result = data.filter((item) => (item[key] as string).includes(query))
  return result.length ? result : null
}

export const isQuestsStateValid = (
  state: ICreationForm
): ICreationForm | false => {
  let isValid: boolean = false
  const newState: ICreationForm = { ...state }
  if (!newState.title.value) {
    isValid = true
    newState.title.error = true
    newState.title.touched = true
  }

  if (!newState.description.value) {
    isValid = true
    newState.description.error = true
    newState.description.touched = true
  }

  if (!newState.startDate.value || !newState.startDate.value.isValid()) {
    isValid = true
    newState.startDate.error = true
    newState.startDate.touched = true
  }

  if (!newState.endDate.value || !newState.endDate.value.isValid()) {
    isValid = true
    newState.endDate.error = true
    newState.endDate.touched = true
  }

  if (!newState.images.value.length) {
    isValid = true
    newState.images.error = 'This filed is required!'
    newState.images.touched = true
  }

  if (!newState.project.value) {
    isValid = true
    newState.project.error = true
    newState.project.touched = true
  }

  return isValid ? newState : false
}

export function stepsMainStateInitialFields(
  data: null | IStep,
  selectedQuest?: string | null
): IStepMainStateCreationForm {
  //there problems connect with types which from @repo/types
  //@ts-ignore
  return {
    //@ts-ignore
    quest: {
      //@ts-ignore
      value: data ? data.quest : selectedQuest ? selectedQuest : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    type: {
      value: data ? data.type : 'on_chain',
      error: false,
      touched: false,
      validations: { required: true }
    },
    xp: {
      value: data ? data.xp.toString() : '',
      error: false,
      touched: false,
      validations: { required: true }
    }
  }
}

export function onChainMainStateInitialFields(data: IOnChainStep | null): any {
  // IOnChainFormState
  return {
    name: {
      value: data ? data.name : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    description: {
      value: data ? data.description : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    actionType: {
      value: data ? data.actionType : 'ownership verification',
      error: false,
      touched: false,
      validations: { required: true }
    },
    assetDetails_type: {
      value: data ? data.assetDetails.type : 'BERC-20',
      error: false,
      touched: false,
      validations: { required: true }
    },
    assetDetails_amount: {
      value: data ? data.assetDetails?.amount?.toString() : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    assetDetails_tokenId: {
      value: data ? data.assetDetails.tokenId : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    linkToStep_text: {
      value: data ? data.linkToStep?.text : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    linkToStep_url: {
      value: data ? data.linkToStep?.url : '',
      error: false,
      touched: false,
      validations: { required: true }
    }
  }
}

export function offChainMainStateInitialFields(
  data: null | IOffChainStep
): IOffChainFormState {
  return {
    name: {
      value: data ? data.name : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    description: {
      value: data ? data.description : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    type: {
      value: data ? data.type : 'information',
      error: false,
      touched: false,
      validations: { required: true }
    },
    information_title: {
      //@ts-ignore
      value: data && data.information ? (data.information as any).title : '',
      error: false,
      touched: false,
      validations: { required: true }
    },
    information_content: {
      value: data && data.information ? (data.information as any).content : '',
      error: false,
      touched: false,
      validations: { required: true }
    }
    // quiz_text: {
    //   value: data && data.quiz ? data.quiz.text : '',
    //   error: false,
    //   touched: false,
    //   validations: { required: true }
    // },
    // quiz_type: {
    //   value: data && data.quiz ? data.quiz.type : 'single',
    //   error: false,
    //   touched: false,
    //   validations: { required: true }
    // }
  }
}

// export function onChainAddressesInitialFields(
//   data: IOnChainStep | null
// ): IOnChainAddressesState {
//   return {
//     contractAddresses: {
//       error: '',
//       value: data?.contractAddresses?.map((el) => {
//         return { value: el, error: false }
//       }) || [{ value: '', error: false }]
//     },
//     walletAddresses: {
//       error: '',
//       value: data?.walletAddresses?.map((el) => {
//         return { value: el, error: false }
//       }) || [{ value: '', error: false }]
//     }
//   }
// }

export const checkAddressIsValid = <T extends IOnChainAddressesState>(
  state: T
): T | boolean => {
  let valid = false
  const newState = deepClone<T>(state)

  Object.keys(newState).forEach((key) => {
    const address = newState[key as keyof IOnChainAddressesState]

    if (!address.value.length) {
      valid = true
      address.error = 'This field is required'
    } else {
      address.value = address.value.map((item) => {
        return item
      })
    }
  })

  return valid ? newState : false
}

export const createOffChainInfoState = (state: IOffChainFormState) => {
  const result: any = {
    name: state.name.value,
    description: state.description.value,
    type: state.type.value,
    information: {
      title: state.information_title.value,
      content: state.information_content.value
    }
  }

  return result
}

export const createOffChainTypeState = (
  state: IOffChainFormState,
  isInfo: boolean,
  options: OptionsStateType
): any => {
  const correctAnswers: number[] = []

  options.value.forEach((el, i) => {
    if (el.isCorrect) {
      correctAnswers.push(i + 1)
    }
  })

  const result: any = {
    name: state.name.value,
    description: state.description.value,
    type: state.type.value
  }

  if (isInfo) {
    result.information = {
      title: state.information_title.value,
      content: state.information_content.value
    }
  } else {
    result.quiz = {
      // text: state.quiz_text.value,
      // type: state.quiz_type.value,
      options: options.value.map((el, i) => {
        return { text: el.text, id: i + 1 }
      }),
      correctAnswers: correctAnswers
    }
  }

  return result
}

export const createOnChainTypeState = (state: IOnChainFormState): any => {
  return {
    name: state.name.value,
    description: state.description.value,
    actionType: state.actionType.value,
    assetDetails: {
      type: state.assetDetails_type.value,
      amount: Number(state.assetDetails_amount.value),
      tokenId: state.assetDetails_tokenId.value
    },
    linkToStep: {
      text: state.linkToStep_text.value,
      url: state.linkToStep_url.value
    }
  }
}

export const checkOptionsIsValid = (
  state: OptionsStateType,
  isMulti: boolean
): OptionsStateType | boolean => {
  let valid = false
  let correctAnswers = 0
  const newState = deepClone<OptionsStateType>(state)
  newState.value = newState.value.map((option) => {
    if (!option.text) {
      valid = true
    }
    if (option.isCorrect) {
      correctAnswers = correctAnswers + 1
    }
    return {
      isCorrect: option.isCorrect,
      error: !option.text,
      text: option.text
    }
  })

  if (isMulti && correctAnswers < 2) {
    valid = true
    newState.error = 'You must select multiple answers.'
  }

  if (!isMulti && correctAnswers !== 1) {
    valid = true
    newState.error = 'You must select single answers.'
  }

  return valid ? newState : false
}

export const checkQuizzesIsValid = (
  quizzes: QuizStateType[]
): QuizStateType[] | false => {
  let valid = false
  const newState: QuizStateType[] = quizzes.map((quiz: QuizStateType) => {
    const newQuiz = deepClone<QuizStateType>(quiz)
    if (!newQuiz.text.value) {
      newQuiz.text.error = 'This field is required.'
      valid = true
    }

    let correctAnswer = 0

    newQuiz.options.value.forEach((option) => {
      if (!option.text) {
        option.error = true
        valid = true
      }
      if (option.isCorrect) {
        correctAnswer = correctAnswer + 1
      }
    })

    if (newQuiz.type === 'single') {
      if (correctAnswer !== 1) {
        valid = true
        newQuiz.options.error = 'You must select single answers.'
      }
    } else {
      if (correctAnswer < 2) {
        valid = true
        newQuiz.options.error = 'You must select multiple answers.'
      }
    }

    return newQuiz
  })

  return valid ? newState : false
}

export const createQuizState = (
  quizzes: any,
  state: IOffChainFormState
): any => {
  const result: any = {
    name: state.name.value,
    description: state.description.value,
    type: state.type.value
  }

  result.quiz = quizzes.map((el: any, index: number) => {
    const text = el.text.value
    const correctAnswers: any = []

    const options: any = el.options.value.map((option: any, index: number) => {
      if (option.isCorrect) {
        correctAnswers.push(index + 1)
      }
      return { text: option.text, id: index + 1 }
    })

    return {
      id: index + 1,
      text: text,
      type: el.type,
      options: options,
      correctAnswers: correctAnswers
    }
  })

  return result
}

export const isNumberFn = (value: string): boolean => {
  return /^[0-9]+$/.test(value)
}

export const getQuizOptionsOptions = (): OptionsStateType => {
  return {
    value: [
      { ...quizOptionInit },
      { ...quizOptionInit },
      { ...quizOptionInit },
      { ...quizOptionInit }
    ],
    error: ''
  }
}

export const quizState: QuizStateType = {
  text: {
    value: '',
    error: false,
    touched: false,
    validations: { required: true }
  },
  type: 'single',
  options: getQuizOptionsOptions(),
  error: ''
}

export const getQuizState = (data: null | IOffChainStep): QuizStateType[] => {
  return data && data?.quiz
    ? data.quiz.map((el) => {
        return {
          text: {
            value: el.text,
            error: false,
            touched: false,
            validations: { required: true }
          },
          type: el.type,
          options: {
            value: el.options.map((option) => {
              return {
                text: option.text,
                error: false,
                isCorrect: el.correctAnswers.includes(option.id)
              }
            }),
            error: ''
          },
          error: ''
        }
      })
    : [deepClone(quizState), deepClone(quizState)]
}

export const sliceString = (value: string, size: number): string => {
  return value.length > size ? `${value.slice(0, size)}...` : value
}
