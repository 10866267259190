import React, { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import AddressesList from 'cellar/src/components/AddressesList'
import { ListItemType } from 'cellar/src/ts-types'
import BasicSelect from 'cellar/src/components/UI/Select'
import {
  onChainAssetsTypeOptions,
  onChainTypeOptions
} from 'cellar/src/constants'
import FadeTransition from 'cellar/src/components/FadeTransition'
import {
  allValidateValue,
  checkAddressIsValid,
  createOnChainTypeState,
  deepClone,
  isNumberFn,
  // onChainAddressesInitialFields,
  onChainMainStateInitialFields,
  TestFieldType,
  validateValue
} from 'cellar/src/utils'
import {
  AddressType,
  IOnChainAddressesState,
  IOnChainFormState
} from '../ts-types'
import Divider from '@mui/material/Divider'
import { IOnChainStep } from '@repo/types'

interface IOnChainFormProps {
  data: IOnChainStep | null
  disabled: boolean
  creationDisabled?: boolean
  confirmTitle: string
  sendState: (state: any, img: null | File) => void
  errorMessage: string | null
  loading: boolean
}

const OnChainForm: FC<IOnChainFormProps> = ({
  disabled,
  confirmTitle,
  sendState,
  errorMessage,
  data,
  creationDisabled,
  loading
}) => {
  const [state, setState] = useState<IOnChainFormState>(
    onChainMainStateInitialFields(data)
  )

  const getInfo = () => {
    let isValid = true
    const keys: (keyof IOnChainFormState)[] = [
      'actionType',
      'assetDetails_amount',
      'assetDetails_type',
      'linkToStep_url',
      'linkToStep_text'
    ]
    keys.forEach((key: keyof IOnChainFormState) => {
      if (!(state[key] as TestFieldType)?.value) {
        isValid = false
      }
    })

    return isValid ? (
      <Box sx={{ textAlign: 'left' }}>
        <p>
          <b>Action:</b>
        </p>
        <p>
          {`${state.actionType.value} at least ${state.assetDetails_amount.value} ${state.assetDetails_type.value}
            tokens (Contract Address:
            `}
        </p>
        <p>
          <b>Links:</b>
        </p>
        <p>
          {`${state.linkToStep_text.value}: ${state.linkToStep_url.value}.`}
        </p>
      </Box>
    ) : (
      ''
    )
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name in state) {
      //@ts-ignore
      const newState: IOnChainFormState = validateValue(state, name, value)
      setState(newState)
    }
  }

  const onConfirm = () => {
    const newState = deepClone<IOnChainFormState>(state)

    //@ts-ignore
    const stateIsValid = allValidateValue<IOnChainFormState>(newState)

    if (!stateIsValid) {
      sendState(createOnChainTypeState(newState as IOnChainFormState), null)
    } else {
      if (stateIsValid) {
        setState(stateIsValid as IOnChainFormState)
      }
    }
  }

  useEffect(() => {
    if (data) {
      setState(onChainMainStateInitialFields(data))
    }
  }, [data])

  return (
    <FadeTransition>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Typography component="h5" textAlign="left">
            Asset Information
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            fullWidth
            disabled={disabled}
            name="name"
            label="Name"
            value={state.name.value}
            helperText={state.name.error}
            error={!!state.name.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            fullWidth
            disabled={disabled}
            name="description"
            label="Description"
            value={state.description.value}
            helperText={state.description.error}
            error={!!state.description.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <BasicSelect
            fullWidth
            withNone={false}
            value={state.actionType.value}
            values={onChainTypeOptions}
            handleChange={onChange}
            title="Action Type"
            label="Action Type"
            name="actionType"
            disabled={disabled}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <BasicSelect
            fullWidth
            withNone={false}
            value={state.assetDetails_type.value}
            values={onChainAssetsTypeOptions}
            handleChange={onChange}
            title="Type"
            label="Type"
            name="assetDetails_type"
            disabled={disabled}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            fullWidth
            name="assetDetails_amount"
            label="Amount"
            value={state.assetDetails_amount.value}
            disabled={disabled}
            helperText={state.assetDetails_amount.error}
            error={!!state.assetDetails_amount.error}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isNumberFn(e.target.value) || e.target.value === '') {
                onChange(e)
              }
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            fullWidth
            disabled={disabled}
            name="assetDetails_tokenId"
            label="Contract Address"
            value={state.assetDetails_tokenId.value}
            helperText={state.assetDetails_tokenId.error}
            error={!!state.assetDetails_tokenId.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <TextField
            fullWidth
            name="linkToStep_text"
            disabled={disabled}
            label="Link Text"
            value={state.linkToStep_text.value}
            helperText={state.linkToStep_text.error}
            error={!!state.linkToStep_text.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
          <TextField
            fullWidth
            name="linkToStep_url"
            disabled={disabled}
            label="Link URL"
            value={state.linkToStep_url.value}
            helperText={state.linkToStep_url.error}
            error={!!state.linkToStep_url.error}
            onChange={onChange}
          />
        </Grid>
        {/*<Grid size={{ xs: 12, sm: 6 }}>*/}
        {/*  <AddressesList*/}
        {/*    disabled={disabled}*/}
        {/*    name="contractAddresses"*/}
        {/*    links={addressesState.contractAddresses.value}*/}
        {/*    error={addressesState.contractAddresses.error}*/}
        {/*    label="Contract Addresses"*/}
        {/*    handleChange={handleAddressChange}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid size={{ xs: 12, sm: 6 }}>*/}
        {/*  <AddressesList*/}
        {/*    disabled={disabled}*/}
        {/*    name="walletAddresses"*/}
        {/*    links={addressesState.walletAddresses.value}*/}
        {/*    error={addressesState.walletAddresses.error}*/}
        {/*    label="Wallet Addresses"*/}
        {/*    handleChange={handleAddressChange}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid size={{ xs: 12 }}>
          <Typography variant="body2" color="error">
            {errorMessage ? errorMessage : ''}
          </Typography>
        </Grid>
        <Grid size={{ xs: 8 }}>
          {getInfo() ? <Alert severity="info">{getInfo()}</Alert> : ''}
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Box sx={{ textAlign: 'right' }}>
            {/*{creationDisabled ? (*/}
            {/*  <Typography color="success">Created</Typography>*/}
            {/*) : (*/}
            <Button
              variant="contained"
              onClick={onConfirm}
              color="primary"
              disabled={disabled}
            >
              {loading ? (
                <Box sx={{ textAlign: 'center' }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                confirmTitle
              )}
            </Button>
            {/*)}*/}
          </Box>
        </Grid>
      </Grid>
    </FadeTransition>
  )
}

export default OnChainForm
