import React, { FC } from 'react'
import FadeTransition from '../../../components/FadeTransition'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import QuestsSelector from '../../../components/QuestsSelector'
import BasicSelect from '../../../components/UI/Select'
import { stepOptions } from '../../../constants'
import TextField from '@mui/material/TextField'
import { isNumberFn } from '../../../utils'
import Divider from '@mui/material/Divider'
import OnChainForm from './OnChainForm'
import OffChainForm from './OffChainForm'
import { ICreationForm } from '../ts-types'
import { IQuest, IStep } from '@repo/types'

interface ICreationFormContainerProps {
  mainState: ICreationForm
  loading: boolean
  selectedQuest: string | undefined | null
  disabled?: boolean
  mainStateChange: (name: string, value: string | number) => void
  quests: IQuest[] | null
  confirmTitle: string
  editStep?: IStep | null
  getChildState: (state: any, img: null | File) => void
  errorMessage: string | null
  isEdit: boolean
}

const CreationFormUi: FC<ICreationFormContainerProps> = ({
  mainState,
  loading,
  selectedQuest,
  disabled,
  mainStateChange,
  quests,
  confirmTitle,
  editStep,
  getChildState,
  errorMessage,
  isEdit
}) => {
  return (
    <FadeTransition>
      <Box sx={{ paddingBottom: '20px' }}>
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid size={{ xs: 12 }}>
            <Typography component="h5" textAlign="left">
              Main Information
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            {quests ? (
              <QuestsSelector
                quests={quests}
                defaultValue={mainState.quest.value}
                disabled={loading || !!selectedQuest || !!disabled || isEdit}
                error={mainState.quest.error}
                name="quest"
                onInputChange={(e, value) => {
                  mainStateChange('quest', value)
                }}
              />
            ) : (
              ''
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <BasicSelect
              fullWidth
              disabled={loading || !!disabled || !!editStep}
              withNone={false}
              value={mainState.type.value}
              values={stepOptions}
              handleChange={({ target }) => {
                mainStateChange(target.name, target.value)
              }}
              title="Type"
              label="Type"
              name="type"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <TextField
              fullWidth
              name="xp"
              disabled={loading || !!disabled}
              label="XP"
              value={mainState.xp.value}
              helperText={mainState.xp.error}
              error={!!mainState.xp.error}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNumberFn(e.target.value) || e.target.value === '') {
                  mainStateChange('xp', e.target.value)
                }
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Divider sx={{ marginTop: '10px' }} />
          </Grid>
          <Grid size={{ xs: 12 }}>
            {mainState.type.value === 'on_chain' ? (
              <OnChainForm
                creationDisabled={disabled}
                data={
                  editStep
                    ? editStep.onChainStep
                      ? editStep.onChainStep
                      : null
                    : null
                }
                errorMessage={errorMessage}
                disabled={!!disabled || loading}
                loading={loading}
                confirmTitle={confirmTitle}
                sendState={getChildState}
              />
            ) : (
              <OffChainForm
                data={
                  editStep
                    ? editStep.offChainStep
                      ? editStep.offChainStep
                      : null
                    : null
                }
                //@ts-ignore
                image={
                  editStep ? (editStep.image ? editStep.image : null) : null
                }
                errorMessage={errorMessage}
                disabled={loading || !!disabled}
                confirmTitle={confirmTitle}
                sendState={getChildState}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </FadeTransition>
  )
}

export default CreationFormUi
