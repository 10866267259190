export enum StepTypeEnum {
  ON_CHAIN = 'on_chain',
  OFF_CHAIN = 'off_chain'
}

export enum OnChainStepTypeEnum {
  OWNERSHIP_VERIFICATION = 'ownership verification',
  NFT_PURCHASE = 'nft purchase',
  NFT_SALE = 'nft sale',
  STAKING = 'staking',
  LENDING = 'lending',
  BORROWING = 'borrowing',
  BRIDGING = 'bridging',
  SWAP = 'swap',
  LIQUIDITY_PROVIDING = 'liquidity providing',
  MINT = 'mint'
}

export enum OffChainStepTypeEnum {
  QUIZ = 'quiz',
  INFORMATION = 'information'
}

export enum QuizTypeEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum StepOnChainAssetTypeEnum {
  BERC_20 = 'BERC-20',
  BERC_721 = 'BERC-721',
  BERC_1155 = 'BERC-1155'
}

export enum StepVerificationStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}
