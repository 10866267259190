import React, { ElementType, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { CustomCard, StyledCardContent } from './style'
import { PROJECTS, QUESTS, ADMINS } from '../../constants'

interface ISectionCard {
  title: string
  Icon: ElementType
}

const SectionCard: FC<ISectionCard> = ({ Icon, title }) => {
  const navigate = useNavigate()

  const onClick = () => {
    switch (title) {
      case 'USERS':
        navigate('/' + ADMINS)
        break
      case 'PROJECTS':
        navigate('/' + PROJECTS)
        break
      case 'QUESTS':
        navigate('/' + QUESTS)
        break
      default:
        navigate('/')
    }
  }

  return (
    <CustomCard>
      <StyledCardContent onClick={onClick}>
        <Typography variant="body1">{title}</Typography>

        <Box sx={{ marginTop: '20px' }}>
          <Icon sx={{ fontSize: '60px' }} />
        </Box>
      </StyledCardContent>
    </CustomCard>
  )
}

export default SectionCard
