import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { ProjectTypeContext } from '../../contexts/projectTypes'
import {
  filterProjectTypes,
  initialProjectTypes
} from '../../contexts/projectTypes/actions'
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete'
import Typography from '@mui/material/Typography'
import { IQuest } from '@repo/types'
import { getQuests } from '../../api'

interface IQuestsSelectorProps {
  defaultValue?: string
  onInputChange: (event: React.SyntheticEvent, value: string) => void
  name: string
  error: boolean | string
  disabled?: boolean
  size?: 'small'
  quests?: IQuest[]
}

const QuestsSelector: FC<IQuestsSelectorProps> = ({
  onInputChange,
  name,
  error,
  disabled,
  size,
  defaultValue,
  quests
}) => {
  const [state, setState] = useState<null | IQuest[]>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')

  const getOptions = () => {
    return state
      ? state.filter((el: IQuest) => el.title.includes(inputValue))
      : []
  }

  const findById = (name: string) => {
    const index = (state as IQuest[]).findIndex((type) => type.title === name)
    return (state as IQuest[])[index]._id
  }

  const onInputChangeAfter = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    switch (reason) {
      case 'selectOption':
        const id = findById(value)
        onInputChange(event, id)
        break
      case 'blur':
        setInputValue(value)
        if (value) {
          const _id = findById(value)
          onInputChange(event, _id ? _id : '')
        } else {
          onInputChange(event, value)
        }
        break
      case 'input':
        setInputValue(value)
        if (!value) {
          onInputChange(event, '')
        }
        break
      case 'clear':
        setInputValue(value)
        onInputChange(event, '')
        break
      case 'removeOption':
        setInputValue(value)
        onInputChange(event, '')
        break
      default:
        // onInputChange(event, value)
        break
    }
  }

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  useEffect(() => {
    if (quests) {
      setState(quests)
    } else {
      ;(async () => {
        try {
          const data = await getQuests({
            page: 1,
            perPage: 1000
          })

          setState(data.data)
        } catch (error: unknown) {
        } finally {
          stopLoading()
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (defaultValue && state && !inputValue) {
      const defaultQuest = state?.find((el) => el._id === defaultValue)
      if (defaultQuest) {
        setInputValue(defaultQuest.title)
      }
    }
  }, [defaultValue, state])

  return (
    <>
      <Autocomplete
        fullWidth
        options={getOptions()}
        inputValue={inputValue}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onInputChange={onInputChangeAfter}
        disabled={!!disabled}
        loading={loading}
        size={size ? 'small' : 'medium'}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps
            }}
            error={!!error}
            // helperText={error ? (error as string) : ''}
            name={name}
            label="Quest"
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option._id}>
              {option.title}
            </li>
          )
        }}
      />
    </>
  )
}

export default QuestsSelector
