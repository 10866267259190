import React, { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FadeTransition from 'cellar/src/components/FadeTransition'
import BasicSelect from 'cellar/src/components/UI/Select'
import { ImageListItem, QuizStateType } from 'cellar/src/ts-types'
import {
  allValidateValue,
  checkQuizzesIsValid,
  createOffChainInfoState,
  createQuizState,
  deepClone,
  getImagesList,
  getQuizState,
  offChainMainStateInitialFields,
  quizState,
  validateValue
} from 'cellar/src/utils'
import { IOffChainFormState } from '../ts-types'
import InformationComponent from './InformationComponent'
import QuizComponent from './QuizComponent'
import Divider from '@mui/material/Divider'
import { IOffChainStep } from '@repo/types'
import ImagesList from '../../../components/ImagesList'
import InputFileUpload from '../../../components/UI/FileUpload'

interface IOffChainFormProps {
  data: IOffChainStep | null
  disabled: boolean
  confirmTitle: string
  sendState: (state: any, img: null | File) => void
  errorMessage: string | null
  image?: null | string
}

export type ImgStateType = {
  value: null | File
  error: string
  touched: boolean
}

const MAX_FILE_SIZE = 10000000

const OffChainForm: FC<IOffChainFormProps> = ({
  disabled,
  confirmTitle,
  sendState,
  errorMessage,
  data,
  image
}) => {
  const [state, setState] = useState<IOffChainFormState>(
    offChainMainStateInitialFields(data)
  )

  const [img, setImg] = useState<ImgStateType>({
    value: null,
    error: '',
    touched: true
  })

  const [quizzes, setQuizzes] = useState<any>(getQuizState(data))

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name in state) {
      //@ts-ignore
      const newState: IOffChainFormState = validateValue(state, name, value)
      setState(newState)
    }
    if (name === 'type') {
      setImg({
        value: null,
        error: '',
        touched: true
      })
    }
  }

  const onConfirm = () => {
    const newState = deepClone<IOffChainFormState>(state)
    const isInfo = state.type.value === 'information'
    let quizzesIsValid: QuizStateType[] | false = false

    //@ts-ignore
    const stateIsValid: any = allValidateValue<IOffChainFormState>({
      name: state.name,
      description: state.description,
      type: state.type,
      ...(isInfo
        ? {
            information_title: state.information_title,
            information_content: state.information_content
          }
        : {})
    })

    if (!isInfo) {
      quizzesIsValid = checkQuizzesIsValid(quizzes)
    }

    if (!stateIsValid && !quizzesIsValid) {
      if (!isInfo) {
        sendState(createQuizState(quizzes, newState), img.value)
      } else {
        sendState(createOffChainInfoState(newState), img.value)
      }
    } else {
      if (isInfo) {
        setState(stateIsValid as IOffChainFormState)
      } else {
        if (stateIsValid) {
          setState(stateIsValid as IOffChainFormState)
        }
        if (quizzesIsValid) {
          setQuizzes(quizzesIsValid)
        }
      }
    }
  }

  const deleteQuiz = (index: number) => {
    const newQuizState: QuizStateType[] = [...quizzes]
    newQuizState.splice(index, 1)
    setQuizzes(newQuizState)
  }

  const onQuizChange = (quiz: QuizStateType, index: number) => {
    const newQuizState: QuizStateType[] = [...quizzes]
    newQuizState[index] = quiz
    setQuizzes(newQuizState)
  }

  const addNewQuiz = () => {
    const newQuizState: QuizStateType[] = [...quizzes, deepClone(quizState)]
    setQuizzes(newQuizState)
  }

  const deleteImg = () => {
    setImg({
      value: null,
      error: '',
      touched: true
    })
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation()
    const files = event.target.files

    if (files && files[0]) {
      if (files[0].size > MAX_FILE_SIZE) {
        setImg({
          value: null,
          error: 'File size exceeds 10MB.',
          touched: true
        })
      } else {
        setImg({
          value: files[0],
          error: '',
          touched: false
        })
      }
    }
  }

  const getImages = (): ImageListItem[] => {
    return [{ src: URL.createObjectURL(img.value as File), name: 'IMG' }]
  }

  useEffect(() => {
    if (image) {
      ;(async () => {
        const { result } = await getImagesList([image])
        setImg({
          value: result[0],
          error: '',
          touched: true
        })
      })()
    }
  }, [])

  return (
    <FadeTransition>
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid size={{ xs: 12 }}>
          <Typography component="h5" textAlign="left">
            Step Information
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            disabled={disabled}
            fullWidth
            name="name"
            label="Name"
            value={state.name.value}
            helperText={state.name.error}
            error={!!state.name.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <TextField
            disabled={disabled}
            fullWidth
            name="description"
            label="Description"
            value={state.description.value}
            helperText={state.description.error}
            error={!!state.description.error}
            onChange={onChange}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <BasicSelect
            disabled={disabled || confirmTitle === 'Save'}
            fullWidth
            withNone={false}
            value={state.type.value}
            values={[
              { title: 'INFORMATION', value: 'information' },
              { title: 'QUIZ', value: 'quiz' }
            ]}
            handleChange={onChange}
            title="Type"
            label="Type"
            name="type"
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
        </Grid>

        {state.type.value === 'information' ? (
          <Grid size={{ xs: 12 }}>
            <InformationComponent
              disabled={disabled}
              state={state}
              onChange={onChange}
            />
          </Grid>
        ) : (
          quizzes.map((quiz: QuizStateType, index: number) => (
            <Grid size={{ xs: 12, md: 6 }}>
              <QuizComponent
                key={`${index}quiz`}
                disabled={disabled}
                state={quiz}
                disabledDelete={quizzes.length === 1}
                deleteQuiz={() => {
                  deleteQuiz(index)
                }}
                onChange={(newQuiz: QuizStateType) => {
                  onQuizChange(newQuiz, index)
                }}
              />
            </Grid>
          ))
        )}
        {state.type.value === 'quiz' && quizzes.length < 4 ? (
          <Grid size={{ xs: 12 }}>
            <Button
              variant="outlined"
              onClick={addNewQuiz}
              color="primary"
              disabled={disabled}
            >
              ADD NEW QUESTION
            </Button>
          </Grid>
        ) : (
          ''
        )}
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          {img.value ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                placeContent: 'space-between',
                width: '100%'
              }}
            >
              <ImagesList
                images={getImages()}
                fullWidth={true}
                onDelete={deleteImg}
              />
            </Box>
          ) : (
            <InputFileUpload
              error={img.error as string}
              onChange={handleFileChange}
              multiple={false}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography variant="body2" color="error">
            {errorMessage ? errorMessage : ''}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={onConfirm}
              color="primary"
              disabled={disabled}
            >
              {disabled ? (
                <Box sx={{ textAlign: 'center' }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                confirmTitle
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FadeTransition>
  )
}

export default OffChainForm
