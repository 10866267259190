import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { Header as HeaderContainer } from 'cellar/src/globalStyles/styles'
import Search from 'cellar/src/components/Search'
import { ADMINS } from '../../../constants'

interface IHeaderProps {
  search: string
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  setEditModal: (value: boolean) => void
}

const Header: FC<IHeaderProps> = ({ onSearch, search, setEditModal }) => {
  return (
    <>
      <HeaderContainer>
        {/*<Search value={search} onChange={onSearch} />*/}
        <Typography variant="body1" color="textPrimary">
          ADMINS
        </Typography>
        <Button
          size="small"
          sx={{ width: '150px' }}
          variant="contained"
          onClick={() => setEditModal(true)}
        >
          <Typography variant="body2">CREATE</Typography>
          {/*<AddIcon />*/}
        </Button>
      </HeaderContainer>
    </>
  )
}

export default Header
