import React, { useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { IReferralListResponse } from '@repo/types'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { ContainerItem, LoadingWrapper } from 'cellar/src/globalStyles/styles'
import { getErrorMessage } from '../../utils'
import { getReferrals, resetXp } from '../../api'
import { ErrorType } from '../../ts-types'
import MessageSnack from '../../components/MessageSnack'
import Body from './components/Body'
import DeleteModal from '../../components/DeleteModal'
import { resetXpText } from '../../constants'
import PaginationWrapper from '../../components/PaginationWrapper'

function ReferralInfo() {
  const [state, setState] = useState<IReferralListResponse | null>(null)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [successMessage, setSuccessMessage] = useState<ErrorType>(null)
  const [transactionId, setTransactionId] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getData = (page: number, perPage: number) => {
    ;(async () => {
      try {
        const data = await getReferrals({
          page: page,
          perPage: perPage
        })
        setState(data)
        stopLoading()
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        setTimeout(() => {
          setErrorMessage('')
        }, 6000)
        stopLoading()
      }
    })()
  }

  const pageChange = (e: React.ChangeEvent<unknown> | null, page: number) => {
    if (page !== state?.page) {
      setLoading(true)
      getData(page, 10)
    }
  }

  useEffect(() => {
    getData(1, 10)
  }, [])

  console.log(state, 'state')

  return (
    <FadeTransition>
      <ContainerItem>
        <Typography variant="h5" sx={{ marginBottom: '30px' }}>
          REFERRALS
        </Typography>

        <LoadingWrapper>
          {!state || loading ? (
            <LinearProgress />
          ) : (
            <>
              <Body state={state.data} setTransactionId={setTransactionId} />
              <div>
                <PaginationWrapper
                  //@ts-ignore
                  count={state.pageCount as number}
                  page={state.page}
                  onChange={pageChange}
                />
              </div>
            </>
          )}
        </LoadingWrapper>

        {errorMessage ? (
          <MessageSnack
            onClose={() => {
              setErrorMessage(null)
            }}
            severity="error"
            message={errorMessage}
          />
        ) : (
          ''
        )}

        {successMessage ? (
          <MessageSnack
            onClose={() => {
              setSuccessMessage('')
            }}
            severity="success"
            message={successMessage}
          />
        ) : (
          ''
        )}
      </ContainerItem>
    </FadeTransition>
  )
}

export default ReferralInfo
