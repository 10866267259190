import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FadeTransition from 'cellar/src/components/FadeTransition'
import RadioBtn from 'cellar/src/components/UI/RadioBtn'
import QuizOptions from 'cellar/src/components/QuizOptions'
import { QuizOptionType, QuizStateType } from 'cellar/src/ts-types'
import { TestFieldType } from 'cellar/src/utils'

interface IQuizComponentProps {
  state: QuizStateType
  onChange: (quiz: QuizStateType) => void
  disabled: boolean
  deleteQuiz: () => void
  disabledDelete: boolean
}

const QuizComponent: FC<IQuizComponentProps> = ({
  onChange,
  state,
  disabled,
  deleteQuiz,
  disabledDelete
}) => {
  const onOptionsChange = (newOptionsState: QuizOptionType[]) => {
    onChange({
      ...state,
      options: { ...state.options, value: newOptionsState, error: '' }
    })
  }

  const textHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const textState: TestFieldType = {
      ...state.text,
      value: value,
      error: value ? false : 'This field is required.'
    }
    onChange({ ...state, text: textState })
  }

  const radioHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...state, error: '' }
    newState.type = e.target.value as 'single' | 'multiple'
    newState.options.value = newState.options.value.map((el) => {
      return { ...el, isCorrect: false }
    })
    onChange(newState)
  }

  return (
    <FadeTransition>
      <Box
        sx={{
          padding: '10px',
          border: 'solid 1px #80808045',
          position: 'relative'
        }}
      >
        {disabledDelete ? (
          ''
        ) : (
          <IconButton
            aria-label="close"
            onClick={deleteQuiz}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.error.main
            })}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Grid container size={{ xs: 12 }} spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Typography component="h5" textAlign="left">
              Quiz Information
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 7 }}>
            <TextField
              disabled={disabled}
              fullWidth
              name="quiz_text"
              label="Quiz Text"
              value={state.text.value}
              error={!!state.text.error}
              onChange={textHandleChange}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 7 }}>
            <QuizOptions
              options={state.options.value}
              onChange={onOptionsChange}
              error={state.options.error}
              multiple={state.type === 'multiple'}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 5 }}>
            <RadioBtn
              handleChange={radioHandleChange}
              row={false}
              value={state.type}
              name="quiz_type"
              options={[
                { label: 'Single', value: 'single' },
                { label: 'Multiple', value: 'multiple' }
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </FadeTransition>
  )
}

export default QuizComponent
