import React, { FC } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import { IQuest, QuestStatusEnum } from '@repo/types'
import FadeTransition from 'cellar/src/components/FadeTransition'
import Actions from 'cellar/src/components/Actions'
import TableWrapper from 'cellar/src/components/TableWrapper'
import { noResultFound, questsHeadRows, STEPS } from 'cellar/src/constants'
import StringCuter from 'cellar/src/components/StringCuter'
import { NoResultFound } from 'cellar/src/globalStyles/styles'
import Image from 'cellar/src/components/UI/Image'
import { formatDate } from '../../../utils'
import { StatusModalType } from '../ts-types'
import { useNavigate } from 'react-router-dom'

interface IBodyProps {
  state: IQuest[]
  openEditModal: (project: IQuest) => void
  openDeleteModal: (id: string) => void
  setStatusModal: (statusData: StatusModalType) => void
}

const Body: FC<IBodyProps> = ({
  state,
  openEditModal,
  openDeleteModal,
  setStatusModal
}) => {
  const navigate = useNavigate()
  const onStatusChange = (statusData: StatusModalType) => {
    setStatusModal(statusData)
  }

  const editSteps = (id: string) => {
    navigate(
      `/${STEPS}?stepsPage=1&perPage=10&stepsQuery=&questId=&type=Bulk&quest-id=${id}&isEdit=true`
    )
  }

  const getStatus = (status: QuestStatusEnum, quest: IQuest) => {
    switch (status) {
      case QuestStatusEnum.ACTIVE:
        return (
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={() => {
              onStatusChange({ id: quest._id, status: quest.status })
            }}
          >
            ACTIVE
          </Button>
        )
      case QuestStatusEnum.PASSIVE:
        return (
          <Button
            size="small"
            variant="outlined"
            color="warning"
            onClick={() => {
              onStatusChange({ id: quest._id, status: quest.status })
            }}
          >
            PASSIVE
          </Button>
        )
      case QuestStatusEnum.COMPLETED:
        return (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              onStatusChange({ id: quest._id, status: quest.status })
            }}
          >
            COMPLETED
          </Button>
        )
    }
  }

  return (
    <FadeTransition>
      <div>
        {state.length ? (
          <TableWrapper headRows={questsHeadRows}>
            {state.map((quest: IQuest) => (
              <TableRow
                key={quest._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Image
                    src={quest.images[0]}
                    width={30}
                    height={30}
                    alt="img"
                  />
                </TableCell>
                <StringCuter value={quest.title} />
                <StringCuter value={quest.description} />
                <TableCell>{formatDate(new Date(quest.startDate))}</TableCell>
                <TableCell>{formatDate(new Date(quest.endDate))}</TableCell>
                <TableCell>{getStatus(quest.status, quest)}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      editSteps(quest._id)
                    }}
                    color="primary"
                  >
                    EDIT STEPS
                  </Button>
                </TableCell>

                <TableCell>
                  <Actions
                    onDelete={() => {
                      openDeleteModal(quest._id as string)
                    }}
                    onEdit={() => {
                      openEditModal(quest)
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableWrapper>
        ) : (
          <NoResultFound variant="h3">{noResultFound}</NoResultFound>
        )}
      </div>
    </FadeTransition>
  )
}

export default Body
