import { clearStorage, getItem } from '../utils'
import { storeTokenName } from '../constants'

const BASE_URL = process.env.REACT_APP_API_HOST || 'http://localhost:3000/'
let token = getItem(storeTokenName)

interface FetchOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  headers?: Record<string, string>
  body?: Record<string, any>
  authRequired?: boolean
  noContentType?: boolean
}

async function fetchClient<T = any>(
  endpoint: string,
  {
    method = 'GET',
    headers = {},
    body,
    authRequired = true,
    noContentType = false
  }: FetchOptions = {}
): Promise<T> {
  const defaultHeaders: HeadersInit = noContentType
    ? {
        ...headers
      }
    : {
        'Content-Type': 'application/json',
        ...headers
      }

  // Conditionally add Authorization header
  if (authRequired) {
    if (token) {
      defaultHeaders['Authorization'] = `Bearer ${token}`
    } else {
      const newToken = getItem(storeTokenName)
      if (newToken) {
        defaultHeaders['Authorization'] = `Bearer ${newToken}`
      }
    }
  }

  const options: RequestInit | any = {
    method: method as RequestInit['method'], // Cast method to the expected type
    headers: defaultHeaders, // No need for extra casting as HeadersInit
    body: body ? (noContentType ? body : JSON.stringify(body)) : undefined
  }

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, options)

    if (!response.ok) {
      if (response.status === 401) {
        clearStorage()
        throw new Error('Unauthorized - Please login again')
      }
      const errorData = await response.json()
      throw new Error(errorData.message || 'An error occurred')
    }

    return (await response.json()) as T
  } catch (error: any) {
    throw error
  }
}

export default fetchClient
