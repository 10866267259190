import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FadeTransition from 'cellar/src/components/FadeTransition'
import { Container } from './styles'
import { getErrorMessage } from '../../utils'
import { getSettings, updateSettings } from '../../api'
import { ErrorType } from '../../ts-types'
import MessageSnack from '../../components/MessageSnack'

type StateItemType = {
  referralPercentage: number | string
  streak: number | string
}

interface IState {
  defaultValue: StateItemType
  inProgressValue: StateItemType
}

function Settings() {
  const [state, setState] = useState<IState | null>(null)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [successMessage, setSuccessMessage] = useState<ErrorType>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value)

    if ((!isNaN(number) && number >= 1) || e.target.value === '') {
      if (e.target.name === 'referralPercentage' && number > 100) {
        return
      }

      if (state && state.inProgressValue) {
        setState({
          ...state,
          inProgressValue: {
            ...state.inProgressValue,
            [e.target.name]: e.target.value === '' ? '' : Number(e.target.value)
          }
        })
      }
    }
  }

  const onSave = () => {
    ;(async () => {
      try {
        await updateSettings({
          referralPercentage: state?.inProgressValue
            .referralPercentage as number,
          streak: state?.inProgressValue.streak as number
        })
        setSuccessMessage('Referral percentage has been successfully changed!')
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        setTimeout(() => {
          setErrorMessage('')
        }, 6000)
      }
    })()
  }

  useEffect(() => {
    ;(async () => {
      try {
        const data = await getSettings()

        setState({
          defaultValue: data as StateItemType,
          inProgressValue: {
            referralPercentage: data.referralPercentage,
            streak: data.streak
          }
        })
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        setTimeout(() => {
          setErrorMessage('')
        }, 6000)
      }
    })()
  }, [])

  return (
    <FadeTransition>
      <>
        <Container>
          <Typography variant="h5" sx={{ marginBottom: '30px' }}>
            SETTINGS
          </Typography>

          <Typography
            variant="h6"
            sx={{
              marginBottom: '5px',
              textAlign: 'left',
              paddingLeft: '10px'
            }}
          >
            Referral percentage
          </Typography>
          <TextField
            fullWidth
            type="number"
            name="referralPercentage"
            value={state?.inProgressValue.referralPercentage}
            helperText="Please enter a number between 0 and 100."
            onChange={onChange}
          />

          <Typography
            variant="h6"
            sx={{
              marginBottom: '5px',
              textAlign: 'left',
              paddingLeft: '10px',
              marginTop: '30px'
            }}
          >
            Streak count
          </Typography>
          <TextField
            fullWidth
            type="number"
            name="streak"
            value={state?.inProgressValue.streak}
            helperText="Please enter a number."
            onChange={onChange}
          />

          <Box sx={{ textAlign: 'right', marginTop: '10px' }}>
            <Button variant="outlined" onClick={onSave} color="primary">
              SAVE
            </Button>
          </Box>
        </Container>
        {errorMessage ? (
          <MessageSnack
            onClose={() => {
              setErrorMessage(null)
            }}
            severity="error"
            message={errorMessage}
          />
        ) : (
          ''
        )}

        {successMessage ? (
          <MessageSnack
            onClose={() => {
              setSuccessMessage('')
            }}
            severity="success"
            message={successMessage}
          />
        ) : (
          ''
        )}
      </>
    </FadeTransition>
  )
}

export default Settings
