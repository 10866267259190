import React, { Suspense, useContext } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import Dashboard from 'cellar/src/pages/Dashboard'
import Projects from 'cellar/src/pages/Projects'
import ProjectTypes from 'cellar/src/pages/ProjectTypes'
import Users from 'cellar/src/pages/Users'
import WebUsers from 'cellar/src/pages/WebUsers'
import Quests from 'cellar/src/pages/Quests'
import Steps from 'cellar/src/pages/Steps'
import Settings from 'cellar/src/pages/Settings'
import ReferralInfo from 'cellar/src/pages/ReferralInfo'
import Login from 'cellar/src/pages/Login'
import { theme } from 'cellar/src/globalStyles/theme'
import {
  QUESTS,
  ADMINS,
  PROJECTS,
  MAIN,
  LOGIN,
  PROJECT_TYPES,
  STEPS,
  SETTINGS,
  REFERRAL_INFO,
  USERS
} from 'cellar/src/constants'
import { UsersProvider } from 'cellar/src/contexts/users'
import { AuthContext } from 'cellar/src/contexts/auth'
import { ProjectTypeProvider } from 'cellar/src/contexts/projectTypes'
import Loading from '../Loading'
import Layout from '../Layout'

// const Dashboard = lazy(() => import('cellar/src/pages/Dashboard'))
// const Projects = lazy(() => import('cellar/src/pages/Projects'))
// const ProjectTypes = lazy(() => import('cellar/src/pages/ProjectTypes'))
// const Users = lazy(() => import('cellar/src/pages/Users'))
// const Quests = lazy(() => import('cellar/src/pages/Quests'))
// const Steps = lazy(() => import('cellar/src/pages/Steps'))
// const Login = lazy(() => import('cellar/src/pages/Login'))

function MainRoutes() {
  const [, state]: any = useContext(AuthContext)
  return state.isAuthenticated ? (
    <UsersProvider>
      <Layout>
        <ProjectTypeProvider>
          {/*<Suspense fallback={<Loading />}>*/}
          <Routes>
            <Route path={MAIN} element={<Dashboard />} />
            <Route path={ADMINS} element={<Users />} />
            <Route path={USERS} element={<WebUsers />} />
            <Route path={PROJECTS} element={<Projects />} />
            <Route path={PROJECT_TYPES} element={<ProjectTypes />} />
            <Route path={QUESTS} element={<Quests />} />
            <Route path={STEPS} element={<Steps />} />
            <Route path={SETTINGS} element={<Settings />} />
            <Route path={REFERRAL_INFO} element={<ReferralInfo />} />
            <Route path="*" element={<Navigate to={MAIN} />} />
          </Routes>
          {/*</Suspense>*/}
        </ProjectTypeProvider>
      </Layout>
    </UsersProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route path="*" element={<Navigate to={LOGIN} />} />
      </Routes>
    </ThemeProvider>
  )
}

export default MainRoutes
