import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import FadeTransition from 'cellar/src/components/FadeTransition'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Header as HeaderContainer } from '../../../globalStyles/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { QUESTS } from '../../../constants'
import { useQueryParams } from '../../../hooks'
import {
  createOffChainStep,
  createOnChainStep,
  editOffChainStep,
  getQuests,
  getSteps as getStepsApi,
  getStep,
  editOnChainStep,
  editOffChainStepImg,
  deleteOffChainStepImg
} from 'cellar/src/api'
import { ErrorType } from '../../../ts-types'
import { getErrorMessage } from '../../../utils'
import {
  ICreateOffChainStepInput,
  ICreateOnChainStepInput,
  IQuest,
  IStep
} from '@repo/types'
import CreationFormContainer from './CreationFromContainer'

interface ICreationFormProps {
  setCreation: (message?: string) => void
  data: string | boolean
  setDeleteModal: (value: string) => void
}

type StateType = {
  disabled: boolean
  created: boolean
  expanded: boolean
  stepData?: IStep
}

const Creation: FC<ICreationFormProps> = ({
  setCreation,
  data,
  setDeleteModal
}) => {
  const { getQueryParam, setQueryParam } = useQueryParams()
  const type = getQueryParam('type')
  const fromQuestPage = getQueryParam('quest-id')
  const bulkEdit: boolean = getQueryParam('isEdit') === 'true'
  const isEdit = type !== 'Create' && type !== 'Bulk'
  const [disabled, setDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [quests, setQuests] = useState<IQuest[] | null>(null)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [step, setStep] = useState<IStep | null>(null)

  const navigate = useNavigate()
  const [state, setState] = useState<StateType[] | null>(null)

  const onClose = () => {
    if (fromQuestPage) {
      navigate('/' + QUESTS)
    } else {
      setCreation()
    }
  }

  const handleChange = (index: number) => {
    if (state) {
      const newState = [...state]
      newState[index] = { ...state[index], expanded: !state[index].expanded }
      setState(newState)
    }
  }

  const createNewStep = () => {
    if (state) {
      setState([
        ...state,
        {
          disabled: false,
          created: false,
          expanded: true
        }
      ])
    }
  }

  const handleDelete = (index: number) => {
    if (state) {
      const newState = [...state]
      newState.splice(index, 1)
      setState(newState)
    }
  }

  const afterCreation = (index: number, edited?: boolean) => {
    if (state) {
      const newState = [...state]
      newState[index] = { disabled: false, expanded: false, created: !edited }
      setState(newState)
    }
  }

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const afterConfirm = (index?: number, isEdit?: boolean) => {
    stopLoading()

    if (type !== 'Bulk') {
      setTimeout(() => {
        setCreation(
          isEdit ? 'Step successfully edited' : 'Step successfully created'
        )
      }, 1000)
    } else {
      setQueryParam('isEdit', 'true')
      afterCreation(index as number, isEdit)
    }
  }

  const onConfirm = (
    body: ICreateOnChainStepInput | ICreateOffChainStepInput,
    isOnChain: boolean,
    isEdit: boolean,
    img: null | File,
    imgId: null | string,
    index?: number,
    id?: string
  ) => {
    setLoading(true)
    ;(isOnChain
      ? isEdit
        ? editOnChainStep(
            body as ICreateOnChainStepInput,
            (id ? id : data) as string
          )
        : createOnChainStep(body as ICreateOnChainStepInput)
      : isEdit
        ? editOffChainStep(
            body as ICreateOffChainStepInput,
            (id ? id : data) as string
          )
        : createOffChainStep(body as ICreateOffChainStepInput)
    )
      .then((result: any) => {
        if (img) {
          const formData: any = new FormData()
          formData.append('image', img)

          editOffChainStepImg(formData, (id ? id : result._id) as string)
            .then(() => {
              afterConfirm(index, isEdit)
            })
            .catch((error) => {
              const message: ErrorType = getErrorMessage(error)
              setErrorMessage(message)
              setTimeout(() => {
                setErrorMessage('')
              }, 8000)
              stopLoading()
            })
        } else {
          if (imgId) {
            deleteOffChainStepImg((id ? id : result._id) as string)
              .then((res) => {
                afterConfirm(index, isEdit)
              })
              .catch((error) => {
                const message: ErrorType = getErrorMessage(error)
                setErrorMessage(message)
                setTimeout(() => {
                  setErrorMessage('')
                }, 8000)
                stopLoading()
              })
          } else {
            afterConfirm(index, isEdit)
          }
        }
      })
      .catch((error: unknown) => {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        setTimeout(() => {
          setErrorMessage('')
        }, 8000)
        stopLoading()
      })
  }

  useEffect(() => {
    if (isEdit) {
      ;(async () => {
        try {
          const stepData = await getStep(type as string)
          setStep(stepData)
        } catch (error: unknown) {
          const message: ErrorType = getErrorMessage(error)
          setErrorMessage(message)
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (type === 'Bulk' && bulkEdit) {
      ;(async () => {
        try {
          const steps = await getStepsApi({
            questId: fromQuestPage as string,
            page: 1,
            perPage: 1000
          })

          setState(
            //@ts-ignore
            [...steps.data].reverse().map((el) => {
              //@ts-ignore
              return {
                disabled: false,
                created: false,
                expanded: false,
                //@ts-ignore
                stepData: { ...el, quest: el.quest._id }
              }
            })
          )
        } catch (error: unknown) {
          const message: ErrorType = getErrorMessage(error)
          setErrorMessage(message)
          setTimeout(() => {
            setErrorMessage('')
          }, 8000)
        }
      })()
    } else {
      setState([
        {
          disabled: false,
          created: false,
          expanded: true
        }
      ])
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await getQuests({
          page: 1,
          perPage: 1000
        })
        setQuests(data.data)
      } catch (error: unknown) {
      } finally {
        // stopLoading()
      }
    })()
  }, [])

  return (
    <FadeTransition>
      <div>
        <HeaderContainer>
          <Button
            size="small"
            sx={{ width: '150px' }}
            variant="contained"
            onClick={onClose}
          >
            <Typography variant="body2">BACK</Typography>
          </Button>
          <Typography component="h5">CREATE NEW STEP</Typography>
        </HeaderContainer>

        {!fromQuestPage ? (
          isEdit ? (
            step ? (
              <CreationFormContainer
                disabled={disabled}
                loading={loading}
                quests={quests}
                title="Save"
                errorMessage={errorMessage}
                onConfirm={onConfirm}
                isEdit={true}
                stepData={step}
              />
            ) : (
              ''
            )
          ) : (
            <CreationFormContainer
              disabled={disabled}
              loading={loading}
              quests={quests}
              title="Create"
              errorMessage={errorMessage}
              onConfirm={onConfirm}
              isEdit={false}
            />
          )
        ) : (
          <>
            {state
              ? state.map((step, index) => (
                  <Box sx={{ marginTop: '10px' }} key={`Accordion${index}`}>
                    <FadeTransition>
                      <Accordion
                        expanded={step.expanded}
                        disabled={step.disabled}
                        onChange={() => {
                          handleChange(index)
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography>STEP {index + 1}</Typography>
                          <Box
                            sx={{
                              marginLeft: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              paddingRight: '10px'
                            }}
                          >
                            {step.created ? (
                              <Typography color="success">Created</Typography>
                            ) : state.length > 1 ? (
                              <DeleteIcon
                                fontSize="medium"
                                color="error"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (step.stepData) {
                                    setDeleteModal(step.stepData._id as string)
                                    handleDelete(index)
                                  } else {
                                    handleDelete(index)
                                  }
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                          <CreationFormContainer
                            disabled={disabled}
                            loading={loading}
                            quests={quests}
                            title={step.stepData ? 'Save' : 'Create'}
                            errorMessage={errorMessage}
                            onConfirm={(body, isOnChain, isEdit, img) => {
                              onConfirm(
                                body,
                                isOnChain,
                                isEdit,
                                img,
                                step.stepData?.image
                                  ? (step.stepData?.image as string)
                                  : null,
                                index,
                                step.stepData?._id
                              )
                            }}
                            isEdit={!!step.stepData}
                            stepData={step.stepData}
                          />

                          {/*<CreationForm*/}
                          {/*  creationDisabled={step.created}*/}
                          {/*  data="Edit"*/}
                          {/*  stepData={step.stepData}*/}
                          {/*  setCreation={setCreation}*/}
                          {/*  afterCreation={() => {*/}
                          {/*    afterCreation(index)*/}
                          {/*  }}*/}
                          {/*/>*/}
                        </AccordionDetails>
                      </Accordion>
                    </FadeTransition>
                  </Box>
                ))
              : ''}

            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
              <Button
                variant="outlined"
                onClick={createNewStep}
                color="primary"
              >
                CREATE NEW STEP
              </Button>
            </Box>
          </>
        )}
      </div>
    </FadeTransition>
  )
}

export default Creation
