import React, { FC } from 'react'
import TableRow from '@mui/material/TableRow'
import { IGetReferralItem } from '@repo/types'
import FadeTransition from 'cellar/src/components/FadeTransition'
import TableWrapper from 'cellar/src/components/TableWrapper'
import { noResultFound, referralHeaderList } from 'cellar/src/constants'
import StringCuter from 'cellar/src/components/StringCuter'
import { NoResultFound } from 'cellar/src/globalStyles/styles'
import { formatDate } from '../../../../utils'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'

interface IBodyProps {
  state: IGetReferralItem[]
  setTransactionId: (value: string) => void
}

const Body: FC<IBodyProps> = ({ state, setTransactionId }) => {
  console.log(state, 'state')

  return (
    <FadeTransition>
      <div>
        {state.length ? (
          <TableWrapper headRows={referralHeaderList}>
            {state.map((item: IGetReferralItem) => (
              <TableRow
                key={(item as any)._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StringCuter value={item.xp.toString()} />
                <StringCuter value={item.quest.title} />
                {/*// @ts-ignore*/}
                <StringCuter value={item.userId._id} />
                {/*// @ts-ignore*/}
                <StringCuter value={item.referralUserId._id} />
                <TableCell>{formatDate(new Date(item.createdAt))}</TableCell>
              </TableRow>
            ))}
          </TableWrapper>
        ) : (
          <NoResultFound variant="h3">{noResultFound}</NoResultFound>
        )}
      </div>
    </FadeTransition>
  )
}

export default Body
