import type { Navigation } from '@toolpad/core/AppProvider'
import GroupIcon from '@mui/icons-material/Group'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BiotechIcon from '@mui/icons-material/Biotech'
import BorderAllIcon from '@mui/icons-material/BorderAll'
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined'
import Settings from '@mui/icons-material/Settings'
import PeopleIcon from '@mui/icons-material/People'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { FormItem, QuizOptionType, SelectValuesItem } from 'cellar/src/ts-types'
import { IProject, ProjectType } from '../pages/Projects/ts-types'
import { FormStateType } from '../utils'
import { IInitialProjectTypeState } from '../contexts/projectTypes/ts-types'
import { IInitialUserState } from '../contexts/users/ts-types'
import FastLogo from 'cellar/public/FastLogo.svg'

export const MAIN = '/'
export const PROJECTS = 'projects'
export const PROJECT_TYPES = 'project-types'
export const ADMINS = 'admins'
export const USERS = 'web-users'
export const QUESTS = 'quests'
export const STEPS = 'steps'
export const SETTINGS = 'settings'
export const LOGIN = 'login'
export const REFERRAL_INFO = 'referral-info'

export const storeTokenName = 'yoQuestsToken'
export const yoQuestEmail = 'yoQuestEmail'

export const NAVIGATION: Navigation = [
  {
    kind: 'header',
    title: 'Main items'
  },
  {
    segment: '',
    title: 'Dashboard',
    icon: <DashboardIcon />
  },
  {
    segment: ADMINS,
    title: 'Admins',
    icon: <GroupIcon />
  },
  {
    segment: USERS,
    title: 'Users',
    icon: <GroupIcon />
  },
  {
    segment: PROJECTS,
    title: 'Projects',
    icon: <AccountTreeIcon />
  },
  {
    segment: PROJECT_TYPES,
    title: 'Project Types',
    icon: <BorderAllIcon />
  },
  {
    segment: QUESTS,
    title: 'Quests',
    icon: <BiotechIcon />
  },
  {
    segment: STEPS,
    title: 'Steps',
    icon: <StairsOutlinedIcon />
  },
  {
    segment: SETTINGS,
    title: 'Settings',
    icon: <Settings />
  },
  {
    segment: REFERRAL_INFO,
    title: 'Referrals',
    icon: <PeopleIcon />
  }
]

export const branding = {
  title: '',
  logo: (
    <img className="ml-4" src={FastLogo} width={40} height={40} alt="logo" />
  )
}

// export function useDemoRouter(defaultPath: string) {
//     const location = useLocation();
//     const navigate = useNavigate();
//
//     return {
//         pathname: location.pathname,
//         navigateTo: (path: string) => navigate(path)
//     };
// }
//

export const projectHeadRows = [
  'Name',
  'Logo',
  'Description',
  'Type',
  'Website',
  'Actions'
]
export const usersHeadRows = ['Email', 'Role', 'status', 'Actions']

export const projectsHeadRows = [
  'Logo',
  'Name',
  'Description',
  'Type',
  'Website',
  'Status',
  'Actions'
]

export const webUsersHeadRows = [
  'Avatar',
  'Email',
  'Nick Name',
  'Balance',
  'Status',
  'Reset XP'
]

export const stepsHeaderRow = [
  'Quest',
  'Type',
  'XP',
  'Name',
  'Description',
  'Action'
]

export const questsHeadRows = [
  'IMG',
  'Title',
  'Description',
  'Start Date',
  'End Date',
  'Status',
  'Edit steps',
  'Actions'
]

export const referralHeaderList = [
  'XP',
  'Quest',
  'User ID',
  'Referral User ID',
  'Created At'
]

export const projectTypesHeadRows = [
  'Name',
  'Created By',
  'Created',
  'Updated',
  'Actions'
]

export const projectDeleteText =
  'Are you sure you want to delete this project? This action cannot be undone.'

export const stepDeleteText =
  'Are you sure you want to delete this step? This action cannot be undone.'

export const projectTypeDeleteText =
  'Are you sure you want to delete this project type? This action cannot be undone.'

export const adminDeleteText =
  'Are you sure you want to delete this Admin? This action cannot be undone.'

export const resetXpText = 'Are you sure you want to reset?'

export const noResultFound = 'No results found!'

///////////////////////////under delete ////////////////////////////
export const mockDataProjects: IProject[] = [
  {
    name: 'NFT',
    logo: 'test',
    description: 'Test description',
    projectType: ProjectType.NFT,
    website: 'test.com',
    socialLinks: ['facebook', 'instagram', 'twitter']
  },
  {
    name: 'JADU',
    logo: 'jadu',
    description: 'Test description',
    projectType: ProjectType.NFT,
    website: 'test.com',
    socialLinks: ['facebook', 'instagram', 'twitter']
  },
  {
    name: 'NIKE',
    logo: 'nike',
    description: 'Nike description',
    projectType: ProjectType.NFT,
    website: 'test.com',
    socialLinks: ['facebook', 'instagram', 'twitter']
  }
]

export const formField: FormItem = {
  error: false,
  value: '',
  touched: false
}

export const validEmailMessage =
  'Please enter a valid email address to continue.'

export const validKeysOfProjectForm = [
  'name',
  'description',
  'projectType',
  'logo',
  'website'
]

export const resetPasswordInitialState: FormStateType = {
  oldPassword: {
    error: false,
    value: '',
    touched: false,
    validations: {
      required: true
    }
  },
  newPassword: {
    error: false,
    value: '',
    touched: false,
    validations: {
      minLength: 6
    }
  },
  confirmPassword: {
    error: false,
    value: '',
    touched: false,
    validations: {
      minLength: 6
    }
  }
}

export const projectTypesDefaultValues: IInitialProjectTypeState = {
  data: [],
  filteredData: null,
  page: 0,
  pageCount: 10,
  totalCount: 0,
  error: '',
  hashMap: {}
}

export const usersDefaultValues: IInitialUserState = {
  data: [],
  filterData: null,
  page: 0,
  hashMap: {},
  pageCount: 0,
  totalCount: 0,
  error: ''
}

export const stepOptions: SelectValuesItem[] = [
  { title: 'ON CHAIN', value: 'on_chain' },
  { title: 'OFF CHAIN', value: 'off_chain' }
]

export const onChainTypeOptions: SelectValuesItem[] = [
  { title: 'OWNERSHIP VERIFICATION', value: 'ownership verification' },
  { title: 'NFT PURCHASE', value: 'nft purchase' },
  { title: 'NFT SALE', value: 'nft sale' },
  { title: 'STAKING', value: 'staking' },
  { title: 'LENDING', value: 'lending' },
  { title: 'BORROWING', value: 'borrowing' },
  { title: 'BRIDGING', value: 'bridging' },
  { title: 'SWAP', value: 'swap' },
  { title: 'LIQUIDITY PROVIDING', value: 'liquidity providing' },
  { title: 'MINT', value: 'mint' }
]

export const onChainAssetsTypeOptions: SelectValuesItem[] = [
  { title: 'BERC-20', value: 'BERC-20' },
  { title: 'BERC-721', value: 'BERC-721' },
  { title: 'BERC-1155', value: 'BERC-1155' }
]

export const quizOptionInit: QuizOptionType = {
  isCorrect: false,
  text: '',
  error: false
}
