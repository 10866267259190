import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { IPartialUser, UserStatusEnum } from '@repo/types'
import FadeTransition from 'cellar/src/components/FadeTransition'
import TableWrapper from 'cellar/src/components/TableWrapper'
import {
  noResultFound,
  projectsHeadRows,
  webUsersHeadRows
} from 'cellar/src/constants'
import StringCuter from 'cellar/src/components/StringCuter'
import { NoResultFound } from 'cellar/src/globalStyles/styles'
import Image from 'cellar/src/components/UI/Image'
import Button from '@mui/material/Button'

interface IBodyProps {
  state: IPartialUser[]
  setTransactionId: (value: string) => void
}

const Body: FC<IBodyProps> = ({ state, setTransactionId }) => {
  const getStatus = (status: UserStatusEnum) => {
    switch (status) {
      case UserStatusEnum.DELETED:
        return (
          <TableCell>
            <Typography color="error">Deleted</Typography>
          </TableCell>
        )
      case UserStatusEnum.ACTIVE:
        return (
          <TableCell>
            <Typography color="success">Active</Typography>
          </TableCell>
        )
      case UserStatusEnum.DISABLED:
        return (
          <TableCell>
            <Typography color="warning">Disabled</Typography>
          </TableCell>
        )
    }
  }

  console.log(state, 'state')

  return (
    <FadeTransition>
      <div>
        {state.length ? (
          <TableWrapper headRows={webUsersHeadRows}>
            {state.map((user: IPartialUser) => (
              <TableRow
                key={user._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {user.avatar ? (
                    <Image
                      src={user.avatar}
                      width={30}
                      height={30}
                      alt="logo"
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
                <StringCuter
                  value={(user.nickname || user.walletAddress) as string}
                />
                <StringCuter value={user.email as string} />
                <StringCuter value={user.xpBalance.toFixed(2).toString()} />
                {getStatus(user.status)}

                <TableCell>
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setTransactionId(user._id as string)
                    }}
                  >
                    RESET
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableWrapper>
        ) : (
          <NoResultFound variant="h3">{noResultFound}</NoResultFound>
        )}
      </div>
    </FadeTransition>
  )
}

export default Body
