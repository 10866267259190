import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import FadeTransition from 'cellar/src/components/FadeTransition'
import PaginationWrapper from 'cellar/src/components/PaginationWrapper'
import { ContainerItem } from 'cellar/src/globalStyles/styles'
import { getUsersList, resetXp } from 'cellar/src/api'
import { ErrorType } from 'cellar/src/ts-types'
import Body from './components/Body'
import { getErrorMessage } from '../../utils'
import MessageSnack from '../../components/MessageSnack'
import DeleteModal from '../../components/DeleteModal'
import { resetXpText } from '../../constants'

function WebUsers() {
  const [state, setState] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null)
  const [transactionId, setTransactionId] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getData = (page: number, perPage: number) => {
    ;(async () => {
      try {
        const data = await getUsersList({
          page: page,
          perPage: perPage
        })
        setState(data)
        stopLoading()
      } catch (error: unknown) {
        const message: ErrorType = getErrorMessage(error)
        setErrorMessage(message)
        setTimeout(() => {
          setErrorMessage('')
        }, 6000)
        stopLoading()
      }
    })()
  }

  const onReset = async () => {
    try {
      await resetXp(transactionId)
      setSuccessMessage('User XP has been successfully reset!')
      const page = state ? state.page : 1
      const perPage = state ? state.perPage : 10
      getData(page, perPage)
      setTransactionId('')
      setTimeout(() => {
        setSuccessMessage('')
      }, 4000)
    } catch (error: unknown) {
      const message: ErrorType = getErrorMessage(error)
      setErrorMessage(message)
      setTimeout(() => {
        setErrorMessage('')
      }, 6000)
    }
  }

  const pageChange = (e: React.ChangeEvent<unknown> | null, page: number) => {
    if (page !== state?.page) {
      setLoading(true)
      getData(page, 10)
    }
  }

  useEffect(() => {
    getData(1, 10)
  }, [])

  return (
    <FadeTransition>
      <ContainerItem>
        <Typography
          sx={{ marginBottom: '20px' }}
          variant="body1"
          color="textPrimary"
        >
          USERS
        </Typography>

        {state && !loading ? (
          <>
            <Body state={state.data} setTransactionId={setTransactionId} />
            <div>
              <PaginationWrapper
                count={state?.pageCount}
                page={state.page}
                onChange={pageChange}
              />
            </div>
          </>
        ) : (
          <LinearProgress />
        )}

        {errorMessage ? (
          <MessageSnack
            onClose={() => {
              setErrorMessage(null)
            }}
            severity="error"
            message={errorMessage}
          />
        ) : (
          ''
        )}

        {successMessage ? (
          <MessageSnack
            onClose={() => {
              setSuccessMessage('')
            }}
            severity="success"
            message={successMessage}
          />
        ) : (
          ''
        )}

        {transactionId ? (
          <DeleteModal
            open={!!transactionId}
            onYes={onReset}
            handleClose={() => setTransactionId('')}
            text={resetXpText}
          />
        ) : (
          ''
        )}
      </ContainerItem>
    </FadeTransition>
  )
}

export default WebUsers
