import React from 'react'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import BiotechIcon from '@mui/icons-material/Biotech'
import { ContainerItem } from 'cellar/src/globalStyles/styles'
import FadeTransition from 'cellar/src/components/FadeTransition'
import SectionCard from 'cellar/src/components/SectionCard'
import { ADMINS } from '../../constants'

function Dashboard() {
  return (
    <FadeTransition>
      <ContainerItem>
        <Typography variant="h3">Dashboard</Typography>

        <Grid container spacing={2} sx={{ marginTop: '10%' }}>
          <Grid sx={{ justifyItems: 'center' }} size={{ xs: 12, sm: 6, md: 4 }}>
            <SectionCard title="ADMINS" Icon={PeopleAltOutlinedIcon} />
          </Grid>
          <Grid sx={{ justifyItems: 'center' }} size={{ xs: 12, sm: 6, md: 4 }}>
            <SectionCard title="PROJECTS" Icon={AccountTreeOutlinedIcon} />
          </Grid>
          <Grid sx={{ justifyItems: 'center' }} size={{ xs: 12, sm: 6, md: 4 }}>
            <SectionCard title="QUESTS" Icon={BiotechIcon} />
          </Grid>
        </Grid>
      </ContainerItem>
    </FadeTransition>
  )
}

export default Dashboard
