import React, { FC } from 'react'
import { IQuest, IStep } from '@repo/types'
import TableWrapper from '../../../components/TableWrapper'
import { noResultFound, stepsHeaderRow } from '../../../constants'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import FadeTransition from 'cellar/src/components/FadeTransition'
import StringCuter from '../../../components/StringCuter'
import Actions from '../../../components/Actions'
import { NoResultFound } from '../../../globalStyles/styles'

interface IBodyProps {
  state: IStep[]
  setDeleteModal: (value: boolean | string) => void
  setCreation: (value: string, questId: string) => void
}

const Body: FC<IBodyProps> = ({ state, setDeleteModal, setCreation }) => {
  const getNameAndDescription = (step: IStep, key: 'name' | 'description') => {
    if (step.type === 'on_chain') {
      return step.onChainStep ? step.onChainStep[key] : ''
    } else {
      return step.offChainStep ? step.offChainStep[key] : ''
    }
  }

  return (
    <FadeTransition>
      <div>
        {state.length ? (
          <TableWrapper headRows={stepsHeaderRow}>
            {state.map((step: IStep, i: number) => (
              <TableRow
                key={step._id ? step._id : 'step' + i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StringCuter value={(step.quest as IQuest)?.title} />
                <TableCell>
                  {step.type === 'on_chain' ? 'ON CHAIN' : 'OFF CHAIN'}
                </TableCell>
                <TableCell>{step.xp}</TableCell>
                <StringCuter value={getNameAndDescription(step, 'name')} />
                <StringCuter
                  value={getNameAndDescription(step, 'description')}
                />
                <TableCell>
                  <Actions
                    onDelete={() => {
                      setDeleteModal(step._id as string)
                    }}
                    onEdit={() => {
                      //@ts-ignore
                      setCreation(step._id as string, step.quest._id)
                    }}
                    onShow={() => {}}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableWrapper>
        ) : (
          <NoResultFound variant="h3">{noResultFound}</NoResultFound>
        )}
      </div>
    </FadeTransition>
  )
}

export default Body
